import React, { useState, useRef, useEffect } from "react";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Editing,
  Grouping,
  Item,
  Form,
  Button as GridButton,
  Popup as GridPopup,
  Summary,
  TotalItem,
  GroupItem,
  Scrolling,
  Sorting,
  Toolbar as GridToolbar,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Accordion } from "devextreme-react/accordion";
import Box from "devextreme-react/box";
import ScrollView from "devextreme-react/scroll-view";
import { NumberBox } from "devextreme-react/number-box";
import { currencyFormat, dateFormat } from "../../utils/formatting";
import { Switch } from "devextreme-react";
import { Template } from "devextreme-react/core/template";
import {
  calculateGridHeight,
  setAgeCellClass,
  setTitleClass,
} from "../../utils/ui";
import styles from "./cashflow.module.scss";

export default function Jobs(props) {
  const [showCashFlowPopup, setShowCashFlowPopup] = useState(false);
  const [editingRowKey, setEditingRowKey] = useState();
  const [rowData, setRowData] = useState();

  const cashflowApiService = new props.cashflowApiService();

  const cashflowDataSource = cashflowApiService.getCashflowDataSource();
  const internalCostsDataSource =
    cashflowApiService.getInternalCostsDataSource(editingRowKey);
  const purchaseInvoiceDataSource =
    cashflowApiService.getPurchaseInvoiceDataSource(editingRowKey);
  const salesInvoiceDataSource =
    cashflowApiService.getSalesInvoiceDataSource(editingRowKey);

  const cashflowGridName = "cashflowGrid";
  const cashflowDataGridRef = useRef(null);
  const paymentsDataGridRef = useRef(null);

  function showCashFlowClick(e) {
    setRowData(e.row.data);
    setEditingRowKey(e.row.data.projectId);
    setShowCashFlowPopup(true);
  }

  useEffect(() => {
    function windowResized() {
      cashflowDataGridRef.current.instance.option(
        "height",
        calculateGridHeight(cashflowGridName),
      );
    }

    window.addEventListener("resize", windowResized);
    return () => window.removeEventListener("resize", windowResized);
  }, []);

  function overheadPercentageUpdated(e) {
    cashflowApiService.updateOverheadPercentage(rowData.projectId, e.value);
  }

  const AccountsReceivablePanel = () => {
    if (showCashFlowPopup) {
      return (
        <Box direction="row" width="100%" height="100%">
          <Item ratio={0} baseSize={200}>
            <Box direction="col" height="100%">
              <Item ratio={0} baseSize={50}>
                <NumberBox
                  value={rowData.invoiced}
                  label="Invoiced to Client"
                  readOnly={true}
                  format={currencyFormat}
                />
              </Item>
              <Item ratio={0} baseSize={50}>
                <NumberBox
                  value={rowData.received}
                  label="Received from Client"
                  readOnly={true}
                  format={currencyFormat}
                />
              </Item>
              <Item ratio={0} baseSize={50}>
                <NumberBox
                  value={rowData.overheadAmount}
                  label="Overhead Allowance"
                  readOnly={true}
                  format={currencyFormat}
                />
              </Item>
              <Item ratio={0} baseSize={50}>
                <NumberBox
                  value={rowData.grossAvailable}
                  label="Gross Funds Available"
                  readOnly={true}
                  format={currencyFormat}
                />
              </Item>
            </Box>
          </Item>
          <Item ratio={0} baseSize={200}>
            <Box direction="col" height="100%">
              <Item ratio={0} baseSize={50} />
              <Item ratio={0} baseSize={50} />
              <Item ratio={0} baseSize={50}>
                <NumberBox
                  name="overheadinput"
                  value={rowData.overheadPercentage}
                  label="Overhead Percentage"
                  width="150px"
                  readOnly={true}
                  onValueChanged={(e) => overheadPercentageUpdated(e)}
                />
              </Item>
              <Item ratio={0} baseSize={50} />
            </Box>
          </Item>

          <Item ratio={3}>
            <DataGrid dataSource={salesInvoiceDataSource}>
              <Column dataField="date" dataType="date" format={dateFormat} />
              <Column dataField="invoiceNumber" />
              <Column dataField="multipleJobs" />
              <Column dataField="amount" format={currencyFormat} />
              <Column dataField="amountOwing" format={currencyFormat} />
              <Column dataField="age" />
            </DataGrid>
          </Item>
        </Box>
      );
    }
  };

  const InternalCostsPanel = () => {
    return (
      <DataGrid dataSource={internalCostsDataSource}>
        <Grouping autoExpandAll={false} />
        <Column
          dataField="employeeName"
          sortIndex={0}
          sortOrder="asc"
          groupIndex={0}
        />
        <Column dataField="accountName" />
        <Column dataField="amount" format={currencyFormat} />
        <Summary>
          <TotalItem
            column="amount"
            summaryType="sum"
            valueFormat={currencyFormat}
            displayFormat="Total: {0}"
          />
          <GroupItem
            column="amount"
            summaryType="sum"
            valueFormat={currencyFormat}
            displayFormat="Total: {0}"
            alignByColumn={true}
          />
        </Summary>
      </DataGrid>
    );
  };

  function payFull(e) {
    var amountOwing = e.row.data.invoiceAmountOwing;
    paymentsDataGridRef.current.instance.cellValue(
      e.row.rowIndex,
      "paymentAmount",
      amountOwing,
    );
    updateNetAvailable();
  }

  function getPaymentAmountTotal(e) {
    if (e.parentType !== "dataRow") return;

    var oldOnValueChanged = e.editorOptions.onValueChanged;
    e.editorOptions.onValueChanged = function (args) {
      oldOnValueChanged.apply(this, arguments);
      updateNetAvailable();
    };
  }

  function updateNetAvailable() {
    var rows = paymentsDataGridRef.current.instance.getVisibleRows();
    var totalPayments = 0;
    rows.forEach((row) => (totalPayments += row.data.paymentAmount));
    var newNetAvailable = rowData.netAvailable - totalPayments;
    var formattedNetAvailable = newNetAvailable.toLocaleString("en", {
      style: "currency",
      currency: "USD",
    });
    document.getElementById("netAvailable").textContent = formattedNetAvailable;
  }

  const saveButtonOptions = { text: "Submit Payments" };

  function styleAgeCells(e) {
    if (e.column.dataField === "age" && e.rowType === "data") {
      e.cellElement.className = setAgeCellClass(e.data.age);
    }
  }

  const AccountsPayablePanel = () => {
    if (showCashFlowPopup) {
      return (
        <div>
          <div className={styles["netAvailableDiv"]}>
            Net Available:{" "}
            <span id="netAvailable">
              {rowData.netAvailable.toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </div>
          <DataGrid
            dataSource={purchaseInvoiceDataSource}
            name="purchaseInvoiceGrid"
            ref={paymentsDataGridRef}
            onEditorPreparing={getPaymentAmountTotal}
            onCellPrepared={styleAgeCells}
            height="700px"
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnMinWidth={100}
          >
            <GridToolbar>
              <Item
                name="saveButton"
                options={saveButtonOptions}
                showText={true}
              />
            </GridToolbar>
            <Editing
              mode="batch"
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={false}
              selectTextOnEditStart={true}
            />
            <Sorting mode="multiple" />
            <Column
              dataField="companyName"
              allowEditing={false}
              defaultSortOrder="asc"
              sortIndex={1}
            />
            <Column
              dataField="invoiceDate"
              caption="Date"
              allowEditing={false}
              dataType="date"
              format={dateFormat}
            />
            <Column dataField="invoiceNumber" allowEditing={false} />
            <Column
              dataField="age"
              allowEditing={false}
              defaultSortOrder="desc"
              sortIndex={0}
            />
            <Column dataField="multipleJobs" allowEditing={false} />
            <Column
              dataField="invoiceAmount"
              caption="Amount"
              format={currencyFormat}
              allowEditing={false}
            />
            <Column
              dataField="invoiceAmountOwing"
              caption="Amount Owing"
              format={currencyFormat}
              allowEditing={false}
            />
            <Column
              dataField="invoicePendingPayments"
              caption="Pending Payments"
              format={currencyFormat}
              allowEditing={false}
            />
            <Column
              dataField="paymentAmount"
              dataType="number"
              format={currencyFormat}
            />
            <Column type="buttons">
              <GridButton text="Pay Full" onClick={(e) => payFull(e)} />
            </Column>
            <Summary recalculateWhileEditing={true}>
              <TotalItem
                column="invoiceAmount"
                summaryType="sum"
                valueFormat={currencyFormat}
              />
              <TotalItem
                column="invoiceAmountOwingAfterPendingPayments"
                summaryType="sum"
                valueFormat={currencyFormat}
              />
              <TotalItem
                name="pendingPayments"
                column="invoicePendingPayments"
                summaryType="sum"
                valueFormat={currencyFormat}
              />
              <TotalItem
                column="paymentAmount"
                summaryType="sum"
                valueFormat={currencyFormat}
              />
            </Summary>
          </DataGrid>
        </div>
      );
    }
  };

  function renderCashFlowPopup() {
    if (showCashFlowPopup) {
      const AccountsRecievableTitle = () => {
        return (
          "Accounts Receivable -  Gross Funds Available: $" +
          rowData.grossAvailable
        );
      };

      const InternalCostsTitle = () => {
        return "Internal Costs - Total: $" + rowData.internalCosts;
      };

      const AccountsPayableTitle = () => {
        return "Accounts Payable";
      };

      return (
        <Popup
          id="cashFlowPopup"
          title={rowData.projectName}
          fullScreen={true}
          visible={showCashFlowPopup}
          showCloseButton={true}
          onHiding={() => {
            setShowCashFlowPopup(false);
          }}
          enableBodyScroll={false}
        >
          <ScrollView width="100%" height="100%">
            <Accordion
              id="cashFlowAccordion"
              collapsible={true}
              multiple={true}
              onContentReady={(e) => {
                e.component.collapseItem(0);
                e.component.expandItem(2);
              }}
            >
              <Item
                titleTemplate={AccountsRecievableTitle}
                component={AccountsReceivablePanel}
              ></Item>
              <Item
                titleTemplate={InternalCostsTitle}
                component={InternalCostsPanel}
              ></Item>
              <Item
                titleTemplate={AccountsPayableTitle}
                component={AccountsPayablePanel}
              ></Item>
            </Accordion>
          </ScrollView>
        </Popup>
      );
    }
  }

  function toolbarItemRender() {
    //TODOAV: I don't understand what this is supposed to do, because if I take it out the grid still works as expected.
    return (
      <Switch
        onValueChanged={(e) => {
          cashflowDataGridRef.current.instance.beginUpdate();
          if (e.value) {
            cashflowDataGridRef.current.instance.columnOption(
              "activeJob",
              "filterValue",
              e.value,
            );
          } else {
            cashflowDataGridRef.current.instance.columnOption(
              "activeJob",
              "filterValue",
              null,
            );
          }
          cashflowDataGridRef.current.instance.endUpdate();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      {renderCashFlowPopup()}
      <h2 className={setTitleClass()}>Cashflow</h2>
      <DataGrid
        id={cashflowGridName}
        dataSource={cashflowDataSource}
        ref={cashflowDataGridRef}
        onEditingStart={(e) => setEditingRowKey(e.key)}
        remoteOperations={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnMinWidth={100}
        height={() => calculateGridHeight(cashflowGridName)}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
        >
          <GridPopup />
          <Form>
            <Item dataField="name" />
            <Item dataField="activeJob" />
            <Item dataField="overheadPercentage" />
          </Form>
        </Editing>
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Paging defaultPageSize={50} />
        <FilterRow visible={true} />
        <Template name="switchActive" render={toolbarItemRender} />
        <Column name="editButton" type="buttons" />
        <Column
          dataField="projectName"
          sortIndex={1}
          sortOrder="desc"
          dataType="string"
        />
        <Column
          dataField="netAvailable"
          format={currencyFormat}
          dataType="number"
        />
        <Column dataField="owing" format={currencyFormat} dataType="number" />
        <Column
          dataField="activeJob"
          visible={true}
          sortIndex={0}
          sortOrder="desc"
          filterValue={true}
          dataType="boolean"
        />
        <Column name="cashflowbutton" type="buttons">
          <GridButton
            name="cashFlowButton"
            text="Cash Flow Analysis"
            onClick={(e) => showCashFlowClick(e)}
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
