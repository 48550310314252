import
  React,
  {
    useRef,
    useState,
    useEffect,
  }
from "react";
import
  DataGrid,
  {
    Toolbar,
    Editing,
    Item,
    Column,
    Button as GridButton,
    GroupPanel,
    SearchPanel,
    ColumnChooser,
    Position,
    FilterRow,
    Scrolling,
    Paging,
    Grouping,
    Summary,
    GroupItem,
    TotalItem,
  }
  from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { currencyFormat } from "../../utils/formatting";
import { Button } from 'devextreme-react/button';
import
{
  calculateGridHeight,
  setAgeCellClass,
  setTitleClass
} from '../../utils/ui';

export default function AccountsPayable(props) {

  const [showAllocationPopup, setShowAllocationPopup] = useState(false);
  const [allocationRowKey, setAllocationRowKey] = useState();
  // eslint-disable-next-line no-unused-vars
  const [invoiceName, setInvoiceName] = useState("");
  const accountsPayableApiService = new props.accountsPayableApiService();
  const accountsPayableDataSource = accountsPayableApiService.getAccountsPayableDataSource();
  const allocationsDataSource = accountsPayableApiService.getAllocationsDataSource(allocationRowKey);
  const accountsPayableGridRef = useRef(null);
  const saveButtonOptions = { text: 'Submit Payments' };
  const accountsPayableGridName = "accountsPayableGrid"

  useEffect(() => {
    function windowResized() {
      accountsPayableGridRef.current.instance.option('height', calculateGridHeight(accountsPayableGridName))
    }

    window.addEventListener('resize', windowResized);
    return () => window.removeEventListener('resize', windowResized);
  }, []);

  function onCellPrepared(e) {
    if (e.column.dataField === 'age' && e.rowType === 'data') {
      e.cellElement.className = setAgeCellClass(e.data.age);
    }
  }

  function onEditingStart(e) {
    if (isInvoiceMultiJobWithPartialPayment(e.data.multipleJobs, e.data.invoiceAmountOwing, e.data.invoiceAmount)) {
      e.cancel = true;
    }
    if (isInvoiceAmountOwingZero(e.data.invoiceAmountOwing)) {
      e.cancel = true;
    }
  }

  function showAllocationsClick(e) {
    setAllocationRowKey(e.data.purchaseInvoiceId);
    setInvoiceName(e.data.invoiceName);
    setShowAllocationPopup(true);
  }

  function renderAllocationButton(element) {
    if (element.data.multipleJobs) {
      return <Button
        text="(split)"
        onClick={() => showAllocationsClick(element)}
        stylingMode='text'
        height="20px"
      />
    }
    else {
      return <div>{element.data.projectName}</div>
    }
  }

  function renderAllocationPopup() {
    if (showAllocationPopup) {
      return (
        <Popup
          visible={showAllocationPopup}
          showCloseButton={true}
          onHiding={() => {
            setShowAllocationPopup(false);
          }}
        >
          <DataGrid
            dataSource={allocationsDataSource}
          >
            <Column dataField="projectName" />
            <Column
              dataField="projectNetAvailable"
              format={currencyFormat}
            />
            <Column
              dataField="invoiceAmountOwing"
              format={currencyFormat}
            />
            <Column
              dataField="invoicePendingPayments"
              format={currencyFormat}
            />
          </DataGrid>
        </Popup>
      );
    }
  }

  function payFull(e) {
    var amountOwing = e.row.data.invoiceAmountOwing;
    accountsPayableGridRef.current.instance.cellValue(e.row.rowIndex, 'paymentAmount', amountOwing);
  }

  function isInvoiceMultiJobWithPartialPayment(multipleJobs, invoiceAmountOwing, invoiceAmount) {
    if (multipleJobs && (invoiceAmountOwing !== invoiceAmount)) {
      return true;
    }
    else {
      return false;
    }
  }

  function isInvoiceAmountOwingZero(invoiceAmountOwing) {
    if (invoiceAmountOwing === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  const isPayFullButtonDisabled = (e) => {
    //The pay full button should be disabled if:
    //  A: the invoice is for multiple jobs and the invoice amount does not match the invoice amount owing (the invoice already has a partial payment)
    //  B: The invoice has an amount owing of 0
    if (isInvoiceMultiJobWithPartialPayment(e.row.data.multipleJobs, e.row.data.invoiceAmountOwing, e.row.data.invoiceAmount)) {
      return true;
    }
    else if (isInvoiceAmountOwingZero(e.row.data.invoiceAmountOwing)) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <React.Fragment>
      {renderAllocationPopup()}
      <h2 className={setTitleClass()}>Accounts Payable</h2>
      <DataGrid
        dataSource={accountsPayableDataSource}
        ref={accountsPayableGridRef}
        onCellPrepared={onCellPrepared}
        onEditingStart={onEditingStart}
        id={accountsPayableGridName}
        height={() => calculateGridHeight(accountsPayableGridName)}
        allowColumnReordering={true}
        remoteOperations={true}
        allowColumnResizing={true}
        columnMinWidth={100}
      >
        <Toolbar>
          <Item name="groupPanel" location="before" />
          <Item name="searchPanel" location="before" />
          <Item name='saveButton'
            options={saveButtonOptions}
            showText={true}
          />
          <Item name="columnChooserButton" location="after" />
        </Toolbar>
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
          selectTextOnEditStart={true}
        />
        <FilterRow
          visible={true}
        />
        <Scrolling
          mode="virtual"
          rowRenderingMode="virtual"
        />
        <ColumnChooser
          enabled={true}
          mode="dragAndDrop"
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
        </ColumnChooser>
        <Paging defaultPageSize={50} />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <SearchPanel visible={true} />
        <Column dataField="companyName" allowEditing={false} allowHiding={false} />
        <Column dataField="invoiceNumber" allowEditing={false} allowHiding={false} />
        <Column dataField="invoiceDate" allowEditing={false} dataType="date" />
        <Column dataField="age" allowEditing={false} defaultSortOrder="desc" sortIndex={0} />
        <Column dataField="ageCategory" allowEditing={false} />
        <Column dataField="multipleJobs" allowEditing={false} allowHiding={false} />
        <Column
          dataField="invoiceAmount"
          caption="Invoice Amount"
          format={currencyFormat}
          allowEditing={false}
        />
        <Column
          dataField="invoiceAmountOwing"
          caption="Amount Owing"
          format={currencyFormat}
          allowEditing={false}
        />
        <Column
          dataField="invoicePendingPayments"
          caption="Pending Payments"
          format={currencyFormat}
          allowEditing={false}
        />
        <Column dataField="projectName" allowEditing={false} cellRender={(e) => renderAllocationButton(e)} />
        <Column
          dataField="projectNetAvailable"
          format={currencyFormat}
          allowEditing={false}
        />
        <Column
          dataField="paymentAmount"
          dataType="number"
          format={currencyFormat}
          allowHiding={false}
        />
        <Column type="buttons" allowHiding={false}>
          <GridButton
            text="Pay Full"
            onClick={(e) => payFull(e)}
            disabled={isPayFullButtonDisabled}
          />
        </Column>
        <Summary>
          <TotalItem
            column="invoiceAmount"
            summaryType="sum"
            valueFormat="currency"
            displayFormat="{0}"
          />
          <TotalItem
            column="invoiceAmountOwing"
            summaryType="sum"
            valueFormat="currency"
            displayFormat="{0}"
          />
          <TotalItem
            column="invoicePendingPayments"
            summaryType="sum"
            valueFormat="currency"
            displayFormat="{0}"
          />
          <GroupItem
            column="invoiceAmount"
            summaryType="sum"
            valueFormat="currency"
            alignByColumn="true"
            displayFormat="{0}"
          />
          <GroupItem
            column="invoiceAmountOwing"
            summaryType="sum"
            valueFormat="currency"
            alignByColumn="true"
            displayFormat="{0}"
          />
          <GroupItem
            column="invoicePendingPayments"
            summaryType="sum"
            valueFormat="currency"
            alignByColumn="true"
            displayFormat="{0}"
          />
        </Summary>
      </DataGrid>
    </React.Fragment>
  );
}