import React from "react";
import DataGrid, {
  Column,
  FilterRow,
} from "devextreme-react/data-grid";
import {
  currencyFormat,
  percentageFormat
} from "../../utils/formatting";
import { setTitleClass } from "../../utils/ui";

export default function ProfitAnalysis(props) {
  const profitAnalysisApiService = new props.profitAnalysisApiService();
  const profitAnalysisDataSource = profitAnalysisApiService.getProfitAnalysisDataSource();

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Profit Analysis</h2>
      <DataGrid
        dataSource={profitAnalysisDataSource}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnMinWidth={100}
      >
        <FilterRow visible={true} />
        <Column dataField="companyName" />
        <Column
          dataField="projectName"
          defaultSortOrder="asc"
        />
        <Column
          dataField="amountInvoiced"
          format={currencyFormat}
        />
        <Column
          dataField="totalExpenses"
          format={currencyFormat}
        />
        <Column
          dataField="grossProfit"
          format={currencyFormat}
        />
        <Column
          dataField="profitPercentage"
          format={percentageFormat}
        />
      </DataGrid>
    </React.Fragment>
  );
}