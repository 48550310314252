import React,
{
  useState,
  useRef,
} from "react";
import DataGrid, {
  Column,
  Editing,
  Item,
  Form,
  Label,
  Lookup,
  Button,
  Paging,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";
import { calculateGridHeight } from '../../utils/ui';
import { setTitleClass } from "../../utils/ui";

export default function Opportunities(props) {
  const [rowData, setRowData] = useState();

  const opportunitiesGridName = "OpportunitiesGrid";
  const opportunitiesGridRef = useRef(null);

  const opportunitiesApiService = new props.opportunitiesApiService();
  const companyApiService = new props.companyApiService();
  const projectStatusLookupApiService =
    new props.projectStatusLookupApiService();

  const opportunitiesDataSource =
    opportunitiesApiService.getOpportunitiesDataSource();
  const projectStatusLookupDataSource =
    projectStatusLookupApiService.getProjectStatusLookupDataSource();
  const companyListDataSource = companyApiService.getCompaniesDataSource();

  const companyEditorOptions = {
    dataSource: companyListDataSource,
    valueExpr: "id",
    displayExpr: "screenName",
    searchMode: "contains",
    searchEnabled: "true",
  };

  function onEditingStart(e) {
    setRowData(e.data);
  }

  const filterValue = ['statusId', '=', 4];

  return (
    <React.Fragment>
      <h2 className={setTitleClass()}>Opportunities</h2>
      <DataGrid
        dataSource={opportunitiesDataSource}
        onEditingStart={(e) => onEditingStart(e)}
        id={opportunitiesGridName}
        ref={opportunitiesGridRef}
        remoteOperations={true}
        height={() => calculateGridHeight(opportunitiesGridName)}
        filterSyncEnabled={true}
        defaultFilterValue={filterValue}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnMinWidth={100}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
        >
          <Form formData={rowData}>
            <Item dataField="name" />
            <Item
              dataField="companyId"
              editorType="dxSelectBox"
              editorOptions={companyEditorOptions}
            >
              <Label text="Company" />
            </Item>
            <Item dataField="bidDate" editorType="dxDateBox" />
            <Item dataField="overheadPercentage" />
            <Item dataField="profitPercentage" />
            <Item dataField="notes" editorType="dxTextArea" />
          </Form>
        </Editing>
        <Scrolling
          mode='virtual'
          rowRenderingMode='virtual'
        />
        <Paging defaultPageSize={50} />
        <FilterRow visible={true} />
        <Column
          type="buttons"
          name="editButton"
        >
          <Button name="edit" />
        </Column>
        <Column
          dataField="name"
          sortIndex={1}
          sortOrder="asc"
          dataType="string"
        />
        <Column
          dataField="companyName"
          dataType="string"
        />
        <Column
          dataField="companyId"
          visible={false}
          dataType="number"
        />
        <Column
          dataField="overheadPercentage"
          visible={false}
          dataType="number"
        />
        <Column
          dataField="profitPercentage"
          visible={false}
          dataType="number"
        />
        <Column
          dataField="bidDate"
          dataType="date"
          visible={false}
          format="yyyy-MM-dd hh:mm aa"
        />
        <Column
          dataField="notes"
          dataType="string"
          visible={false}
        />
        <Column
          dataField="statusId"
          caption="Status"
          sortIndex={0}
          sortOrder="desc"
          dataType="number"
        >
          <Lookup
            dataSource={projectStatusLookupDataSource}
            valueExpr="id"
            displayExpr="value"
          />
        </Column>
        <Column
          type="buttons"
          name="deleteButton"
        >
          <Button name="delete" />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
